<template>
  <div
    role="chooseProductType"
    class="flex items-center border rounded bg-gray-200 w-max p-1 cursor-pointer"
  >
    <template v-for="product in productTypes" :key="product">
      <span
        @click="$emit('update:modelValue', product)"
        class="px-3 border rounded py-1"
        :class="{
          'bg-white font-medium text-gray-500': modelValue === product
        }"
      >
        {{ formatProductNmame(product) }}
      </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { PropType } from "vue";

defineProps({
  productTypes: {
    type: Object as PropType<string[]>,
    required: true
  },
  modelValue: {
    type: String,
    required: true
  }
});

const formatProductNmame = (product: string) =>
  ["Arloc", "Erc"].includes(product) ? product.toUpperCase() : product;
</script>
